import { createTheme, genPageTheme, darkTheme, shapes } from '@backstage/theme';

const colors = {
  aqua: {
    DEFAULT: '#59D5DC',
    100: '#EEFBFC',
    200: '#D6F5F6',
    300: '#BDEEF1',
    400: '#8BE2E7',
    500: '#59D5DC',
    600: '#50C0C6',
    700: '#43A0A5',
    800: '#358084',
    900: '#2C686C',
  },
  coal: {
    DEFAULT: '#2B2F2C',
    100: '#EAEAEA',
    200: '#CACBCA',
    300: '#AAACAB',
    400: '#6B6D6B',
    500: '#2B2F2C',
    600: '#272A28',
    700: '#202321',
    800: '#1A1C1A',
    900: '#151716',
  },
  lime: {
    DEFAULT: '#B5FD42',
    100: '#F8FFEC',
    200: '#EDFFD0',
    300: '#E1FEB3',
    400: '#CBFE7B',
    500: '#B5FD42',
    600: '#A3E43B',
    700: '#88BE32',
    800: '#6D9828',
    900: '#597C20',
  },
  purple: {
    DEFAULT: '#4A0A77',
    100: '#EDE7F1',
    200: '#D2C2DD',
    300: '#B79DC9',
    400: '#8054A0',
    500: '#4A0A77',
    600: '#43096B',
    700: '#380859',
    800: '#2C0647',
    900: '#24053A',
  },
};

const theme = createTheme({
  fontFamily: [
    'Overpass',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  palette: {
    ...darkTheme.palette,
    primary: {
      ...colors.aqua,
      main: colors.aqua.DEFAULT,
    },
    secondary: {
      ...colors.aqua,
      main: colors.aqua.DEFAULT,
    },
    background: {
      ...colors.coal,
      default: colors.coal.DEFAULT,
    },
    border: colors.aqua.DEFAULT,
    navigation: {
      background: colors.coal[900],
      indicator: colors.aqua[400],
      selectedColor: colors.purple[100],
      color: colors.purple[100],
      navItem: {
        hoverBackground: colors.coal.DEFAULT,
      },
      submenu: {
        background: colors.coal[700],
      },
    },
  },
  pageTheme: {
    home: genPageTheme({
      colors: [colors.purple.DEFAULT, colors.coal[500]],
      shape: shapes.round,
    }),
  },
  defaultPageTheme: 'home',
});

export default theme;
