import React from 'react';
import { makeStyles } from '@material-ui/core';

import logo from './pub_aqua.png';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return <img src={logo} alt="Pub" className={classes.svg} />;
};

export default LogoIcon;
